import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import api from "plugins/api";
import { useSnackbar } from "notistack";
import {useState} from "react";

export const fetchCustomerClaim = createAsyncThunk(
  "customer/findCustomerClaim",
  async (params, thunkAPI) => {
    const response = await api.get(
      `customers/${params.customer_id}/claims/${params.claim_id}`
    );

    response.data.statusCloseDisabled = false;
    if (['ethica', 'ethica-sigorta'].includes(response.data.customer.slug)) {
      if (response.data.status.code === 'CLAIM_STATUS_CLOSED') {
        response.data.statusCloseDisabled = true;
      }
    }

    return response.data;
  }
);

export const fetchdownloadAllFiles = createAsyncThunk(
  "claim/downloadAllFiles",
  async (params, thunkAPI) => {

    const response = await api.get(
      `customers/${params.customer_id}/claims/${params.claim_id}/uploads/download`,
      {responseType:"blob"}
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'files.zip'); //or any other extension
      document.body.appendChild(link);
      link.click();

      // return fileDownload(response.data, filename)
    })
  }
)

const entityAdapter = createEntityAdapter({
  selectId: (entity) => entity.id,
});

export const entitySelectors = entityAdapter.getSelectors(
  (state) => state.customerClaims
);

const { actions, reducer } = createSlice({
  name: "customer/claim",
  initialState: entityAdapter.getInitialState({
    loading: false,
  }),
  reducers: {},
  extraReducers: {
    [fetchCustomerClaim.fulfilled]: (state, action) => {
      entityAdapter.upsertOne(state, action);
    },
    [fetchCustomerClaim.pending]: (state, action) => {},
    [fetchCustomerClaim.rejected]: (state, action) => {},
  },
});

export default reducer;
